<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        <a-row>
            <a-col span="12" offset="5">
                <h4>Product</h4>
            </a-col>
        </a-row>
        <a-row v-if="!inEdit">
            <a-col span="12" offset="5">
                <a-form-item label="Product url">
                    <a-input placeholder="product url" v-model="newproducturl"></a-input>
                </a-form-item>
                <a-form-item>
                    <a-button v-on:click="validateproduct()">Validate</a-button>
                </a-form-item>
                <a-form-item>{{vstatus}}</a-form-item>
            </a-col>
        </a-row>
        <a-form layout='horizontal' v-show="inEdit"
            :form="form" 
            :label-col="{span:5}" 
            :wrapper-col="{span:12}"
            @submit="handleSubmit"
            >
            <a-form-item label="Name">
                <a-input placeholder="Please fill in the name of the product"
                    v-decorator="['name',{rules: [{ required:true}]}]"
                >
                </a-input>
            </a-form-item> 
            <a-form-item label="Description">
                <a-textarea placeholder="(Optional) Please fill in the description of the product"
                    v-decorator="['description']"
                >
                </a-textarea>
            </a-form-item>       
            <a-form-item label="Product ID">
                <a-input placeholder="Please fill in the ID of the product in your own organization"
                    v-decorator="['uniqueid',{rules: [{ required:true}]}]"
                >
                </a-input>
            </a-form-item>
            <a-form-item label="Url">
                <a-input placeholder="Please fill in the website URL of your own product"
                    v-decorator="['url']"
                >
                </a-input>
            </a-form-item> 
            <a-form-item label="Image">
                <a-input placeholder="Images"
                    v-decorator="['image']"
                >
                </a-input>
            </a-form-item> 
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" icon="save" @click="handleSubmit">Save</a-button>
                <a-space></a-space>
                <a-button type="secondary" icon="close" style="margin-left:10px" @click="backtoproductlist">Cancel</a-button>
            </a-form-item>
        </a-form>
        <a-row v-if="inEdit">
            <a-col span="16"></a-col>
            <a-col span="8">
                <a-button icon="plus" type="primary" shape="round" v-if="productid!='0'" @click="addurl()">Add competitor url</a-button>
                <a-button class="ml-20" size="small" @click="aiproduct" type="primary" shape="round" icon="google" v-if="productid!='0'">AI</a-button>
            </a-col>
        </a-row>
        <a-row>
            <a-col span="12" offset="5">
                <a-table :data-source="data.urls" :columns="
                [{title:'Url',dataIndex:'url'},
                 {title:'     ',			
                 dataIndex: 'id',
                 key: 'editid',
                 scopedSlots: { customRender: 'delBtn' },
                 width: 50}]">
                    <template slot="delBtn" slot-scope="row">
                        <a-button v-if="!row.isdefault" shape="circle" icon="delete" :data-id="row.key"  @click="delurl(row)">
                        </a-button>
                    </template>
                </a-table>
            </a-col>
        </a-row>
    </a-card>
</template>
<script>
import {addorupdate, getitem, deleteurl,getvalidationid, getvalidationstatus} from '@/api/product';

import { message } from 'ant-design-vue';

export default ({
    methods: {
        validateproduct() {
            this.currenturl = this.newproducturl;
            this.validating = true;
            getvalidationid({url:this.newproducturl}).then(
                result=> {
                    console.log(result);
                    this.testid= result.testid;
                    this.stopcheck = false;
                    this.startcheck();
                }
            );   
        },
        startcheck(){
            if (!this.stopcheck)
            {
                var that = this;
                getvalidationstatus({testid:this.testid}).then(
                    result=>{
                        console.log(result);
                        that.vstatus = result.data.vstatus;
                        that.vdata = result.data.vdata;
                        if ( (result.data.vstatus>-1) && (result.data.vstatus<3)) {
                            setTimeout(() => {
                                that.startcheck();
                            }, 3000);
                        } else {
                            that.stopcheck = true;
                            that.validating= false;
                            that.inEdit = true;
                            that.form.setFieldsValue(
                                {
                                    name: that.vdata.name,
                                    description: that.vdata.description,
                                    url: that.currenturl,
                                    uniqueid: that.vdata.id,
                                    image: that.vdata.image
                                }
                            );
                        }
                    }
                );
            }
        },
        aiproduct() {
            this.$router.push({
                path: "productaisuggestion",
                query: { productid: this.productid },
            });
        },
        addurl() {
            // function: product-12
            this.$router.push({
                path: "/addurl",
                query: { productid: this.productid },
            });
        },

        delurl(urlid){
             deleteurl({ url: urlid, id: this.data.productKey }).then((data) => {
                for (var i = 0; i < this.data.urls.length; i++) {
                    if (this.data.urls[i].id == urlid) {
                        this.data.urls.splice(i, 1);
                        break;
                    }
                }
            });
        },
        //function: product-08
        backtoproductlist(){
            this.$router.go(-1);
        },
        //function: product-07
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err,values)=>{
                if (!err){
                    values.id=this.productid;
                    addorupdate(values).then(data=>{
                        if (data['status']=='OK'){
                            message.success('This product has been saved successfully!');
                            this.$router.go(-1);
                        }
                        if(data['status']=='Fail'){
                            message.error('This Product ID ' + values.uniqueid +' already exists and cannot be added. Please update the product page if needed.',10)
                        }
                    });
                }
            });
        }
    },
    data() {
        return {
            hello:'',
            name:'-',
            form: this.$form.createForm(this,{name: 'product'}),
            data: {},
            newproducturl:'',
            stopcheck: false,
            testid:'',
            vstatus:'',
            vdata:'',
            currenturl:'',
            inEdit: false,
            validating: false
        };
    },
    props:{
        productid: String
    },
    mounted() {
        var id = this.productid;
        if (id==0){
            this.form.setFieldsValue(
                {
                    name:'',
                    description:"",
                    url:"",
                    uniqueid:''
                }
            );
        } else {
            this.inEdit = true;
            getitem(id).then(data=>{
                this.data = data;
                this.form.setFieldsValue(
                    {
                        name: data.name,
                        description: data.description,
                        url: data.url,
                        uniqueid: data.uniqueid,
                        image: data.image
                    }
                );
            });
        }
    }
})
</script>
